.guide-sap {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    justify-content: center;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }
    &__title {
        display: flex;
        font-family: 'Lato';
        font-weight: bold;
        font-size: 34px;
        line-height: 36px;
        color: #0B1870;
        margin: 0 auto;
        text-align: center;
        max-width: 700px;
        
    }

    &__sub-title {
        font-family: 'OpenSans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #000021;
        margin: 56px 0 36px 0;
        text-align: center;

    }

    &__list {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;
        list-style: none;
        flex-wrap: wrap;
        margin: 0;
    }

    &__item {
        display: flex;
        min-height: 60px;
        max-width: 226px;
        padding-left: 80px;
        margin: 20px 10px;
        align-items: center;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            width: 60px;
            height: 60px;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
        }

        &--consalting {
            &::before {
                background: url("/img/guide-sap/icon-consalting.svg") no-repeat center left;
                background-size: contain;
            }
        }
        &--adaptation {
            &::before {
                background: url("/img/guide-sap/icon-adaptation.svg") no-repeat center left;
                background-size: contain;
            }
        }
        &--development {
            &::before {
                background: url("/img/guide-sap/icon-development.svg") no-repeat center left;
                background-size: contain;
            }
        }
        &--optimization {
            &::before {
                background: url("/img/guide-sap/icon-optimization.svg") no-repeat center left;
                background-size: contain;
            }
        }
        &--support {
            &::before {
                background: url("/img/guide-sap/icon-support.svg") no-repeat center left;
                background-size: contain;
            }
        }
    }

    &__button {
        margin: 92px 0 0 0;
        position: relative;
        display: block;
    }
}

@media(max-width: 768px) {
    .guide-sap {
        &__container {
            justify-content: center;
            min-height: 0;
            width: 100%;
            flex-wrap: wrap;
        }

        &__title {
            font-size: 18px !important;
            line-height: 22px;
        }

        &__sub-title {
            margin: 15px 0 5px 0;
            font-size: 12px !important;
            line-height: 16px;
            text-align: center;
        }

        &__list {
            margin: 0 auto;
            flex-direction: column;
            align-items: center;
            padding: 0 40px;
        }

        &__item {
            margin: 0;
            width: 100%;
            padding: 0 0 0 60px;

            &::before {
                width: 35px;
                height: 35px;
            }
        }

        &__button {
            button {
                padding: 25px;
            }
            width: 80% !important;
            margin: 15px auto 0 auto;
        }
    }
}