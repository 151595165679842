@import './Fonts.scss';

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;

  font-family: 'OpenSans';//,Lato sans-serif;
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: inherit;
}

a {
  text-decoration: none;

  color: inherit;
}

.button {
  z-index: 1;

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-height: 60px;
  padding: 12px 102px;

  color: #FFFFFF;
  border: none;
  border-radius: 41px;
  outline: none;
  background: #FB6247;

  font-family: 'OpenSans';
  font-size: 24px;
  line-height: 1;
  cursor: pointer;

  position: relative;
  
  &:after,
  &:before {
      position: absolute;
      z-index: -1;
      top: 0;
      right: -50%;
      left: -50%;
      height: 0;
      padding-bottom: 200%;
      content: '';
      transition: transform 0.5s, background 1s;
      transition-timing-function: ease-out, ease-out;
      border-radius: 230px;
      background-color:  darken(#FB6247, 20%);
      -webkit-transition: transform 0.5s, background 1s;
      -moz-transition: transform 0.5s, background 1s;
      -ms-transition: transform 0.5s, background 1s;
      -o-transition: transform 0.5s, background 1s;
}
  
  &:after {
      transform: translate3d(-5%,6em,0) rotate(-45deg);

      border-top-right-radius: 40%;
      border-bottom-left-radius: 40%;
  }
  
  &:before {
      transform: translate3d(5%,6em,0) rotate(0deg);

      border-top-left-radius: 40%;
      border-bottom-right-radius: 40%;
  }
  
  &:hover,
  &:focus {      
      &:before,
      &:after {
      transform: translate3d(0,-5em,0) rotate(180deg);

      background-color: darken(#FB6247, 20%);
      }
  }
}

.button--disabled {
  background: rgb(151, 147, 147);
  cursor: default;
  
  &::after,
  &::before {
      display: none;
  }
}

.button--contact {
  position: relative;

  width: 60px;
  height: 60px;
  padding: 0;

  border-radius: 6px 0px 0px 6px;
  background-color: #FB6247;
  &:before {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    height: 100%;
    padding: 0;

    content: '';
    transition: none;
    transform: none;

    border: none;
    background: url('/img/contacts-phone.svg') no-repeat center;
  }
  &:after {
      padding-bottom: 400%;

      transform: translate3d(-5%,6em,0) rotate(-45deg);

      border-top-right-radius: 40%;
      border-bottom-left-radius: 40%;
  }
  

  
  &:hover,
  &:focus {      
      &:after {
      transform: translate3d(0,-5em,0) rotate(160deg);

      background-color: darken(#FB6247, 20%);
      }
      &:before {
        transition: none;  transform: none;

        border: none;
        background-color: transparent;
        }
  }
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 60px 60px;
  color: #000021;

  align-items: center;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
  }

  &--imprint {
    &::after {
      background: none;
    }
  }
}

input {
  outline: none;
  
  &:active,
  &:hover {
    box-shadow: inset 0px 1px 5px #FB6247;
  }
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  width: 100%;
  position: relative;

  button {
    margin: 70px 0 0 0;
  }
}

.title {
  font-size: 24px;
  margin: 0 0 20px 0;
  text-align: center;

  &--white {
    color: #0B1870;
  }
  &--blue {
    color: #FFFFFF;
  }
}

.main-title {
  text-align: center;
  font-family: 'Lato';
  font-weight: 900;
  font-size: 40px;
  line-height: 46px;
  margin: 0 0 80px 0;

  &--white {
    color: #0B1870;
  }
  &--blue {
    color: #FFFFFF;
  }
}

h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
}

@media (max-width: 768px) {
  h1 {
      font-size: 22px !important;
  }
  h2 {
    font-size: 18px !important;
  }
  h3 {
    font-size: 16px;
    line-height: 20px;
  }
  span, p, li {
    font-size: 12px !important;
  }

  .button {
    font-size: 18px;
    min-height: 60px;
    padding: 10px 57px 10px 57px;
    min-width: 0;
  }

  .container {
    align-items: center;
    margin: 0;

    button {
      margin: 30px 0 0 0;
    }
  }
  .section {
    padding: 25px 15px;
  }
  .main-title {
    font-size: 18px !important;
    line-height: 22px;
    margin: 0 0 25px 0;
  }
  .title {
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 5px 0;
  }
}