.resolve-sap-ariba {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: linear-gradient(90deg, 
    #000040 50.04%, 
    #091686 102.58%);
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &__container {
        padding: 63px 117px 178px 117px;
        max-width: 1280px;
        margin: 0;
        width: 100%;
    }

    &__title {
        font-family: 'Lato';
        font-weight: 900;
        font-size: 40px;
        line-height: 46px;
        text-align: center;
        width: 100%;
        color: #FFFFFF;
        margin: 0;
    }

    &__list {
        margin: 52px 0 0 0;
        font-family: 'OpenSans';
        font-weight: normal;
        font-size: 18px;
        line-height: 40px;
        color: #FFFFFF;
        list-style: none;
        padding: 0;
    }

    &__item {
        margin:0;
        padding: 10px 0 10px 70px;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            left: 0px;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            height: 50px;
            background-size: contain;
        }
    }
    &__item--1 {
        &::before {
            background: url(/img/check-sap-ariba/1.svg)  no-repeat center;
        }
    }
    &__item--2 {
        &::before {
            background: url(/img/check-sap-ariba/2.svg)  no-repeat center;
        }
    }
    &__item--3 {
        &::before {
            background: url(/img/check-sap-ariba/3.svg)  no-repeat center;
        }
    }
    &__item--4 {
        &::before {
            background: url(/img/check-sap-ariba/4.svg)  no-repeat center;
        }
    }
}


@media(max-width: 768px) {
    .resolve-sap-ariba {
        &__container {
            padding: 25px 15px;
        }
        &__text {
            margin: 25px 0 0 0; 
        }
        &__title {
            text-align: center;
            font-style: normal;
            font-weight: 900;
            font-size: 18px !important;
            line-height: 22px;
        }

        &__list {
            margin: 25px 0 0 0; 
        }

        &__item {
            &::before {
                display: none;
            }
            line-height: 16px;
            padding: 0 0 16px 0;

            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}