.custom-accordion {
    align-content: center;
    display: flex;

    width: 100%;
    max-width: 1280px;
    min-height: 90px;
    margin: 0 auto;
    margin-bottom: 20px;

    color: #000021;

    font-family: 'OpenSans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;
    
    li {
        list-style-type: none;
    }

    &.active {
        .custom-accordion {
            &__list {
                display: block;

                ul {
                    padding-left: 0;
                }
            }
        }
    }
    &__block {
        margin: 0 auto;
        width: 640px;
        min-height: 90px;

        border-radius: 0 8px 8px 0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    }
    &__title {
        display: flex;
        align-items:  center;
        justify-content: space-between;

        width: 100%;
        min-height: 90px;
        margin: 0;
        padding: 30px 30px 30px 65px;

        cursor: pointer;

        color: #fff;
        border-radius: 8px;
        background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);

        font-size: 24px;
        font-weight: bold;
        line-height: 28px;
        img {
            min-width: 62px;  height: 100%;
            }
    }
    &__list {
        display: none;

        margin: 0;
        padding: 0;
        padding-top: 45px;
        padding-bottom: 20px;

        list-style: none;
    }
    &__item {
        position: relative;

        width: 100%;
        margin-bottom: 15px;
        padding-right: 50px;
        padding-left: 140px;

        cursor: pointer;

        &::before {
            position: absolute;
            top: 7px;
            left: 100px;

            display: block;

            content: '';
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: linear-gradient(164.08deg, #000040 2.97%, #091686 78.06%);
        }
    }
}

@media (max-width: 768px) {
    .custom-accordion {
        margin: 0;
        &__title {
            padding: 15px;
            border-radius: 0;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
        }
        &__block {
            border-radius: 0px;
            margin-top: 10px;
        }
        &__item {
            padding-left: 50px;
            &:last-child {
                margin: 0;
            }
            
            &::before {
                left: 19px;
            }
        }
        &__list {
            padding: 15px 0;
        } 

    }
}