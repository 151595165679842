.card-list {
    position: relative;

    width: 100%;

    text-align: center;

    color: #000021;

    font-family: 'OpenSans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;

    &__wrap {
        width: 100%;
        max-width: 1170px;
        margin: 0 auto;
        
        padding: 60px 60px;

        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
    }

    &__list {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: center;

        margin: 0;
        margin-top: 30px;
        margin-left: -18px;
        padding: 0;

        list-style-type: none;
    }
    &__title {
        margin: 0 0 60px 0;

        text-align: left;
        white-space: pre-wrap;
        letter-spacing: 1px;

        font-family: 'Lato';
        font-size: 34px;
        font-weight: 900;
        font-style: normal;
        line-height: 36px;
    }
    &__item {
        position: relative;

        width: 31.5%;
        min-height: 230px;
        margin-bottom: 20px;
        margin-left: 18px;
        padding: 20px;

        color: #000021;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    }
    &__item-img-wrap {
        display: flex;
        justify-content: flex-start;

        font-size: 0;

        img {
            height: 100%;
            max-height: 88px;
        }
    }
    &__item-text {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        min-height: 85px;
        margin: 0;
        margin-top: 15px;
    }
    &__item-title {
        display: inline-block;

        margin: 0;
        margin-top: 6px;
        margin-left: 20px;

        text-align: left;

        color: #000021;

        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 22px;
    }
}

.card-list--theme-white {
    background: #fff;
    .card-list {
        &__title {
            color: #0B1870;;
        }
    }
}

.card-list--theme-blue {
    background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);
    .card-list {
        &__title {
            color: #fff;
        }
    }
}

.card-list--without-title {
    .card-list {
        &__item-title {
            display: none;
        }
        &__item-img-wrap {
            top: 0;
            right: 0;
            left: 0;

            align-items: flex-end;
            justify-content: center;

            width: 100%;
            height: 100px;

            img {
                height: 84px;
            }
        }
        &__item-text {
            align-items: center;
        }
    }
}

@media(max-width: 768px) {
    .card-list {
        &__wrap {
            padding: 25px 15px;
        }
        &__title {
            margin: 0;
            font-weight: 900;
            
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-align: center;
        }
        &__list {
            margin-top: 15px;
        }

        &__item {
            display: flex;
            width: 100%;
            margin-left: 17px;
            flex-direction: column;
            min-height: 0px;
            height: fit-content;
            align-items: center;
            padding: 15px;
            &-title {
                margin: 0;
                text-align: left;

                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                min-height: 0px;
                max-height: 100%;
            }

            &-text, &-img-wrap {
                text-align: left;
                min-height: 0px;
                max-height: 100%;
            }
        }
    }
    .card-list--without-title {
        .card-list {
            &__item-title {
                display: none;
            }
            &__item-img-wrap {
                top: 0;
                right: 0;
                left: 0;
    
                align-items: flex-end;
                justify-content: center;
    
                width: 100%;
                height: 50px;
    
                img {
                    height: 84px;
                }
            }
            &__item-text {
                align-items: center;
            }
        }
    }
    .card-list--without-icon {
        .card-list {
            &__item-img-wrap {
                top: 0;
                right: 0;
                left: 0;
    
                align-items: flex-end;
                justify-content: center;
    
                width: 100%;
                height: 50px;
    
                img {
                    display: none;
                }
            }
            &__item-title, &__item-text {
                align-items: center;
                text-align: center;
            }
        }
    }
}