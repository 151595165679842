
.go-to-sap-bw4-hana {
    padding: 60px 0 80px 0;
    width: 100%;
    background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);

    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    color: #FFFFFF;

    &__wrap {
        margin: 0 auto;
        width: 100%;
        max-width: 1030px;

    }
    &__title {
        margin: 0;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-size: 40px;
        line-height: 46px;

        text-align: center;
        letter-spacing: 1px;

        color: #FFFFFF;
    }
    &__subtext {
        margin: 0 auto;
        margin-top: 70px;
        color: white;
        text-align: center;
        max-width: 545px;
    }
    &__subtitle {
        text-align: center;
        margin: 0;
        margin-top: 60px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 38px;

        color: #FFFFFF;
    }
    &__button {
        position: relative;
        margin: 0 auto;
        margin-top: 60px;
    }
}

@media (max-width: 768px) {
    .go-to-sap-bw4-hana {
        padding: 25px 15px;
        &__title {
            text-align: center;
            line-height: 22px;
        }
        &__subtitle {
            margin: 25px 0;
        }
        &__subtext {
            margin: 10px 0 0 0;
        }
        &__button {
            display: none !important;
        }
        
    }
}