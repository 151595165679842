.development {
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);

    &.white {
        background: #FFFFFF;
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1170px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: right top 230px;
        padding: 57px 15px 53px;
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    &__title {
        text-align: center;
        margin: 0 auto;
        width: 100%;
        font-family: 'Lato';
        font-weight: 900;
        font-size: 40px;
        line-height: 46px;
        color: #FFFFFF;

        &.white {
            color: #0B1870;
        }
    }

    &__left {
        width: 100%;
        max-width: 516px; 
        margin: 0;
        margin-top: 25px;
        margin-right: auto;
    }

    &__sub-title {
        font-family: 'OpenSans';
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
        font-weight: bold;
        margin: 45px 0 0 0;

        &.white {
            color: #0B1870;
        }
    }

    &__list {
        padding: 0;
        margin: 15px 0 0 0;
    }

    &__item {
        list-style-type: none;
        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 22px;
        color: #FFFFFF;
        margin-bottom: 10px;

        &.white {
            color: #000021;

            &::before{
                background: linear-gradient(164.08deg, #000040 2.97%, #091686 78.06%);
            }
        }

        padding-left: 36px;
        position: relative;
        
        &::before{
            content: '';
            position: absolute;
            top: 6px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: white;
        }
    }

    &__icon-item {
        display: flex;
        flex-direction: row;
        align-content: center;
        min-width: 120px;

        &.white {
            color: #000021;
        }
    }

    &__icons {
        padding: 0;
        margin: 22px 0 0 0;
    }

    &__icon-container {
        margin: 0 0 29px 0;
        list-style-type: none;
        display: flex;
        font-family: 'OpenSans';
        font-size: 17px;
        color: #FFFFFF;

        align-items: center;
        
        &__title {
            min-width: 100px;
        }
    }
    &__icon {
        background-size: contain !important;

        height: 46px;
        &.little {
            width: 46px;
        }
        &.middle {
            width: 70px;
        }
        &.big {
            width: 150px;
        }
        &__title {
            margin: 0 12px;
            display: flex;
            align-self: center;
        }
    }

    &__right {
        width:100%;
        max-width: 450px;
        margin: auto 0;
        font-size: 30px;
        line-height: 44px;
        font-weight: bold;
        font-family: 'OpenSans';
        color: #FFFFFF;

        &.white {
            color: #1936A8;
        }

        p {
            margin: 44px 0 0 0;
        }
    }

    &__bottom {
        width: 100%;
    }

    &__button {
        margin: 77px auto 0 auto;
        position: relative;
        display: block;
    }
}


@media(max-width: 768px) {
    .development {
        &__icon-container {
            margin: 0;
            flex-wrap: wrap;

            &__title {
                width: 100%;
                padding: 10px 0;
            }
        }
        &__container {
            padding: 30px 15px;
            background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);
            &.white {
                background: white;
            }
        }
    
        &__list {
            margin: 20px 0 0 0; 
            flex-wrap: wrap;
        }
        &__title {
            padding: 0 8px;
            font-size: 18px !important;
            line-height: 22px;
            font-weight: 900;
        }
        &__sub-title {

            margin: 15px 0;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
        }
        &__left {

            margin: 0;
            order: 2;
        }
        &__right {
            margin: 22px 0 7px 0;
            font-size: 16px !important;
            line-height: 20px;
            text-align: center;
            font-weight: 400;
            order: 1;
            p{
                margin: 0 0 15px 0;

                &:last-child{
                    margin-bottom: 0px;
                }
            }
        }
        &__item {
            &::before{
                top: 6px;
                left: 6px;
                width: 7px;
                height: 7px;
            }
        }
    }
}