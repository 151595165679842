.implementation-sap-bw4-hana {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    color: #000021;
    background: #fff;

    font-family: 'OpenSans';
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;

    &__title {
        margin: 0;

        text-align: center;

        color: #0B1870;

        font-family: 'Lato';
        font-size: 34px;
        font-weight: bold;
        font-style: normal;
        line-height: 38px;
    }
    &__subtext {
        max-width: 800px;
        margin: 0;
        margin-top: 30px;
        padding: 0 15px;

        text-align: center;
    }
    &__list {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        text-align: center;
        flex-wrap: wrap;
        justify-content: center;

        width: 100%;
        max-width: 1030px;
        margin: 0;
        margin: 50px 0 -70px -18px;
        padding: 0 15px;

        list-style-type: none;
    }
    
    &__item {
        position: relative;

        width: 31.5%;
        min-height: 230px;
        max-height: 238px;
        margin-bottom: 20px;
        margin-left: 20px;
        padding: 20px 30px;

        color: #000021;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    }
    &__item-img-wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 84px;

        font-size: 0;

        img {
            max-height: 84px;
        }
    }
    &__item-text {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        min-height: 85px;
        margin: 0;
        margin-top: 15px;
    }
}

@media (max-width: 768px) {
    .implementation-sap-bw4-hana {
        &__item {
            width: 100%;
            min-height: 0px;

            &-text {
                min-height: 0px;
                margin: 0 0 15px 0;
            }
        }
        &__subtext {
            margin: 0;
            font-weight: 600;
            font-size: 12px !important;
            line-height: 16px;
        }
        &__list {
            margin: 35px 0 0px 0;
            padding: 0;
        }
        &__item {
            padding: 0;
            margin-left: 0;
        }
    }
}