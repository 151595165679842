.nominated-card-list {
    width: 100%;

    text-align: center;

    color: #000021;

    font-family: 'OpenSans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;

    &__wrap {
        width: 100%;
        margin: 0 auto;
        
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        line-height: 22px;
    }

    &__list {
        display: flex;
        align-content: stretch;
        align-items: stretch;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 0;
        margin-top: 50px;
        margin-left: -20px;
        padding: 0;

        list-style-type: none;

        counter-reset: heading; // Инициируем счетчик
    }
    &__title {
        margin: 40px 0;

        text-align: center;

        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 22px;

        text-align: center;

        color: #FFFFFF;
    }
    &__item {
        position: relative;

        width: 270px;
        height: 185px;
        margin-bottom: 20px;
        margin-left: 70px;
        padding: 20px;

        color: #000021;
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
        &::before {
            position: absolute;
            top: 0;
            left: -47px;
            counter-increment: heading;
            content: counter(heading);
            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 52px;
            line-height: 60px;

            letter-spacing: 1px;

            color: #FFFFFF;

        }
    }

    &__item-text {
        display: flex;
        align-items: flex-start;
        justify-content: center;

        margin: 0;
        margin-top: 20px;
    }
    &__item-title {
        display: inline-block;

        margin: 0;


        text-align: center;

        color: #000021;

        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 22px;
    }
}

@media (max-width: 768px) {
    .nominated-card-list {
        &__title {
            font-weight: 600;
            font-size: 12px !important;
            line-height: 16px;
            /* or 133% */
            text-align: center;
            letter-spacing: 0.01em;
            margin: 20px 0;

            color: #FFFFFF;

            
        }
        &__list {
            margin: 0;
        }
        &__item {
            &-title {
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
            }
            &-text {
                margin: 10px 0 0 0;
            }
            width: 100%;
            text-align: left;
            margin: 0 0 15px 20px;

            margin-left: 50px;
            padding: 15px; 
            height: fit-content;
        }
    }
}