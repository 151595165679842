.list-with-сircles {
    text-align: center;
    
    &__list {
        padding: 0;
        list-style-type: none;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-wrap: wrap;
    
        font-family: 'OpenSans';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
    }
    
    &__item {
        width: 22%;
    }
    
    &__item-img {
        width: 17vw;
        height: 17vw;
        margin: 0 auto;
        border-radius: 100%;

        background-size: contain;
        background-position: center top;
        background-repeat: no-repeat;
    }
    
    &__item-text {
        margin: 0;
        padding: 17px 0;
    }
    &__wrap {
        margin-top: 50px;
    }
}

.list-with-сircles--blue {
    background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);
    color: #FFFFFF !important;
}

.list-with-сircles--white {
    background: #fff;
    color: #000021;

    &::before {
        content: '';
        position: absolute;
        top: -5px;
        left: 0;
        width: 100%;
        height: 6px;
        background: white;
    }

    .list-with-сircles {
        &__title {
            color: #0B1870;
        }
    }
}

@media (max-width: 768px) {
    .list-with-сircles {
        align-items: center;
        justify-content: space-between;

        &__list {
            margin: 25px 0 0 0;
        }

        &__item {
            width: 47%;
        }
        &__item-img {
            width: 40vw;
            height: 40vw;
            border-radius: 100%;
        }
        &__item-text {
            font-size: 12px !important;
            line-height: 16px;
        }
    }
}

@media (min-width: 1900px) {
    .list-with-сircles {
        align-items: center;
        justify-content: space-between;

        &__list {
            margin: 25px 0 0 0;
        }

        &__item {
            width: 25%;
        }
        &__item-img {
            width: 13vw;
            height: 13vw;
            border-radius: 100%;
        }
        &__item-text {
            font-size: 12px !important;
            line-height: 16px;
        }
    }
}