.statement {
    position: relative;
    display: flex;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    color: #fff;
    //у всех элементов в нутри statement z-index должен быть больше 1
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__title {
        width: 100%;
        padding: 0 15px;
        font-weight: 900;
        font-size: 52px;
        line-height: 73px;
        text-align: center;
        letter-spacing: 1px;
        font-family: 'Lato';

        margin: 0;
    }
    
    &__advantages-item {
        position: relative;
        padding-left: 37px;
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #fff;
            left: 0;
            top: 49%;
        }
    }
    &__content {
        margin: auto 0;
        text-align: center;
    }
    &__subtitle {
        margin: 57px 0 0 0;
        font-size: 34px;
        line-height: 44px;
        z-index: 1;
        width: 100%;
        max-width: 990px;
        padding: 0 15px;
        text-align: center;
        white-space: pre-line;
    }

}
/* 
    у страницы main и imprint "уникальные" стейтменты, с частично измененными стилями, 
    что прпоисаны в .statement--main и .statement--imprint
*/
.statement--main {
    position: relative;

    .statement__title {
        display: none;
    }

    .statement__content {
        width: 90vh;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
        align-self: center;
    }

    .statement__content__link {
        display: flex;
        position: relative;
        width: 25vh;
        height: 25vh;
        align-items: center;
        justify-content: center;

        font-size: 2.5vh;
        font-weight: 600;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        background-image: url('/img/statement/infographics/ring.svg');

        &:nth-child(1), &:nth-child(6), &--0, &--5 {
            margin: 0 5vh 0 15vh;
        }
        &:nth-child(2), &:nth-child(7), &--1, &--6 {
            margin: 0 15vh 0 5vh;
        }
        &:nth-child(4), &--3 {
            width: 40vh;
            background-image: url('/img/statement/infographics/core.svg');
        }
    }
}
/*
    у страницы main и imprint "уникальные" стейтменты, с частично измененными стилями, 
    что прпоисаны в .statement--main и .statement--imprint
*/
.statement--imprint {
    .statement {
        &__content {
            width: fit-content;
        }
        &__subtitle {
            text-align: left;
            font-style: normal;
            font-weight: bold;
            font-size: 34px;

            padding: 0;
            margin-top: 40px;
        }
        &__microtitle {
            text-align: left;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;

            margin: 0;
            margin-top: 20px;
        }
        &__text {
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            white-space: pre-line;
        }
    }
}

@media(max-width: 768px)
{
    
    .statement {

        &__title {
            margin: 0;
            padding: 0;
            font-size: 22px !important;
            line-height: 26px;
            text-align: center;
        }
        &__subtitle {
            margin: 16px auto 0 auto;
            width: auto;
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 18px !important;
            line-height: 21px;
            /* or 111% */
        }

        &__title {
            display: inline;
        }

        min-height: 322px;

        .arrow {
            display: none;
        }
    }
    
    .statement--main {
        &:after{
            display: none;
        }
        .statement{
            &__title {
                display: flex;
                white-space: pre-wrap;
                text-align: center;
                line-height: 40px;
            }
            &__content {
                display: block;
                width: auto;
            }
        }
        
    }

    .statement--imprint {
        .statement__subtitle {
            font-size: 16px !important;
        }
        .statement {
            &__content {
                width: auto;
                margin-left: 15px;
                margin-right: 15px;
            }
            &__subtitle {
                text-align: center;
                font-size: 16px;
    
                padding: 0;
                margin-top: 20px;
                letter-spacing: 0.01em;
            }
            &__microtitle {
                text-align: center;
                font-size: 16px;
    
                margin: 0;
                margin-top: 0px;
                letter-spacing: 0.01em;
            }
            &__text {
                margin-top: 20px;
                font-size: 16px;
                text-align: center;
                letter-spacing: 0.01em;
            }
        }
    }
}