.installing-sap-s4hana {
    position: relative;

    width: 100%;


    text-align: center;

    color: #000021;

    font-family: 'OpenSans';
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    line-height: 24px;

    &::before {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 4px;

        content: '';

        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 4px;

        content: '';

        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &__wrap {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 140px;
    }
    &__title {
        width: 100%;
        margin: 0;

        text-align: center !important;
        letter-spacing: 1px;

        color: #0B1870;

        font-family: 'Lato';
        font-size: 40px;
        font-weight: 900;
        font-style: normal;
        line-height: 46px;
    }
    &__subtitle {
        width: 100%;
        margin: 0;
        margin-top: 25px;

        text-align: center;

        color: #0B1870;

        font-family: 'OpenSans';
        font-size: 34px;
        font-weight: bold;
        font-style: normal;
        line-height: 38px;
    }
    &__installation-options {
        display: flex;
        justify-content: space-between;

        width: 100%;
        margin: 0;
        margin-top: 40px;
        margin-bottom: 45px;
        padding: 0;

        list-style: none;

        &__item {
            display: flex;
            border-radius: 8px;
            width: 31.6%;
            min-height: 238px;

            background-size: cover;

            div {
                display: flex;
                border-radius: 8px;
                width: 100%;
                height: 100%;


                align-items: center;
                align-content: center;
                color: white;
                justify-content: center;
            }
        }

        img {
            position: relative;
            width: 100%;
            height: 100%;

            object-fit: cover;
            z-index: -1;
        }

        span {
            font-family: 'OpenSans';
            font-size: 18px;
            line-height: 22px;

            color: #FFFFFF;
        }
    }
    &__implementation-methods {
        display: flex;
        justify-content: space-between;

        width: 100%;
        margin: 0;
        margin-top: 50px;
        padding: 0;

        list-style: none;

        h4 {
            width: 100%;
            margin: 0;

            text-align: center;

            color: #000021;

            font-family: 'OpenSans';
            font-size: 18px;
            line-height: 22px;
        }

        p {
            margin: 0;
            margin-top: 25px;
        }

        li {
            width: 48.9%;
            padding: 25px;

            border-radius: 8px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
        }
    }

    &__subtext {
        display: block;

        width: 100%;
        margin-top: 25px;
        margin-bottom: 75px;

        text-align: left;

        color: #000021;

        font-size: 12px;
        line-height: 16px;
    }

    &__experience {
        display: flex;
        justify-content: space-between;

        width: 100%;
        margin-top: 65px;

        p {
            margin: 0;
            width: 490px;
            text-align: left;
        }
    }
    &__button {
        position: relative;
        margin: 0 auto;
        margin-top: 90px;
        overflow: hidden;
    }
}

@media (max-width: 768px) {
    .installing-sap-s4hana {
        &__installation-options {
            flex-direction: column;
            margin-top: 20px;
            margin-bottom: 0;
            &__item{
                width: 100%;
                border-radius: 8px;
                color: #FFFFFF;
                font-size: 18px;
                line-height: 60px;
                height: 60px;
                min-height: 0;

                margin: 5px 0;
            }
        }

        &__wrap {
            padding: 0 0;
        }
        &__title {
            line-height: 22px;
        }
        &__subtitle {
            font-weight: bold;
            line-height: 20px;
            margin: 15px;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
        }
        &__implementation-methods {
            flex-direction: column;
            margin-top: 10px;
            
            li {
                margin: 0 0 10px 0;
                width: 100%;

                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
                border-radius: 8px;

                height: auto;
                min-height: auto;
                padding: 15px;

                text-align: left;
                p {
                    margin: 0;
                }
            }
            h4 {
                font-size: 12px;
                line-height: 14px;
                margin:0 0 12px 0;
            }
        }
        &__subtext {
            margin: 8px 12px 0 12px;
            width: calc(100% - 24px);
        }
        &__experience {
            flex-direction: column;
            margin: 0;
            width: 100%;

            p {
                width: 100%;
                font-size: 12px;
                letter-spacing: 0.01em;
                font-weight: normal;
                font-size: 12px;
                line-height: 16px;
            }
        }
        &__button {
                margin: 25px 35px 0px 35px;
        }
    }
}