.non-sap-solutions {
    background: linear-gradient(90deg, 
    #010139 10.79%, 
    rgba(11, 12, 82, 0.768867) 46.66%, 
    rgba(12, 13, 85, 0.255874) 73.02%, 
    rgba(1, 1, 57, 0) 94.64%);
    background-image: url("/img/main-page/non-sap-solutions-background.png");

    ::after, ::before {
        display: none !important;
    }

    .table {
        display: flex;
        color: #FFFFFF;
        margin-top: 55px;
        font-family: 'OpenSans';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        width: 100%;
        justify-content: space-between;
        color: #FFFFFF;
        flex-direction: row;
            
        .tr {
                
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.01em;
            display: flex;                
            justify-content: space-between;
            flex-direction: column;

            .td {
                &:first-child {
                    font-family: 'OpenSans';
                    font-weight: bold;
                    
                    padding-bottom: 20px;
                }
                line-height: 28px;
                margin: 0;
            }
        }
    }
    
    &__title{
        text-align: left;
        color: #0B1870;
        margin: 0;

        &.white {
            color: white;
        }
    }
}

@media (max-width: 768px) {
    .non-sap-solutions {
        background-image: none;
        background: linear-gradient(90deg, #000040 0%, #091686 100%);
        
        &__title{
            font-size: 18px !important;
            margin: 0 0 10px 0;
        }

        &__container {
            justify-content: space-between;
        }
        .table {
            margin: 0;
            width: 100%;
            padding: 0 15px;
            .tr {
                .td {
                    line-height: 18px;
                }
                min-width: 45%;
                font-size: 12px;
                margin: 20px 0 0 0;
            }
            flex-wrap: wrap;
        }
        &__button {
            margin: 0;
        }
    }
}
