.small-info-block {
    display: flex;
    justify-content: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }
    &:first-child {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
        }
    }

    &__container {
        max-width: 1030px;
        width: 100%;
        padding: 115px 60px;
        display: flex;
        flex-direction: column;
        min-height: 420px;
    }

    &__title {
        font-family: 'Lato';
        font-weight: '900';
        font-size: 40px;
        color: #FFFFFF;
        margin: 0;
    }

    &__info {
        font-family: 'OpenSans';
        font-size: 18px;
        color: #FFFFFF;
        margin: 20px 0 0 0;
        max-width: 50%;

    }

    &__button {
        margin: 77px 0 0 0;
        position: relative;
        display: block;
    }
    height: fit-content;
}

.left {
    background-position: right;
    text-align: left;

    background-repeat: no-repeat;
    background-size: cover;

    .small-info-block__info, .small-info-block__button {
        margin-right: auto;
    }

}
.right {
    background-position: left;
    text-align: right;

    background-repeat: no-repeat;;
    background-size: cover;

    .small-info-block__info, .small-info-block__button {
        margin-left: auto;
    }

}
@media (max-width: 768px) {
    .small-info-block {
        &__container {
            padding: 25px 15px;
            min-height: 240px;
        }
        &__info {
            max-width: 100%;
        }
        &__button {
            margin: auto auto 0 auto;
        }
    }
}