.marked-list {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    &__wrap {
        width: 100%;
    }
    
    &__title {
        font-family: 'Lato';
        font-weight: bold;
        font-size: 24px;
        color: #0B1870;

        margin: 0;
        text-align: left;

        &.blue-and-white {
            padding-left: 36px;
            &::before {
                content: '';
                position: absolute;
                top: 8px;
                left: 4px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: linear-gradient(164.08deg, #000040 2.97%, #091686 78.06%);
            }
        }
        &.white {
            color: white;
            margin-bottom: 0px;
        }
    }
    // единственный белый список находится на главной, нужно было убрать у него остсутп
    &.white {
        margin-bottom: 0px;
    }
    &__list {
        padding: 0;
        margin: 30px 0;
        font-family: 'Lato';
        font-weight: 400;
        font-size: 18px;
        color: #000021;
        list-style: none;
    }
    
    &__item {
        padding-left: 36px;
        margin-bottom: 18px; 
        position: relative;
        list-style-type: none;

        &:last-child{
            margin-bottom: 0;
        }
        
        &::before{
            content: '';
            position: absolute;
            top: 8px;
            left: 4px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: linear-gradient(164.08deg, #000040 2.97%, #091686 78.06%);
        }
        &.blue-and-white {
            &::before {
                width: 6px;
                height: 6px;
                background: #FFFFFF;
                border: 2px solid #091686;
            }
        }
        &.white {
            color: white;
            &::before {
                background: #FFFFFF;
            }
        }
    }
}

@media (max-width: 768px) {
    .marked-list {
        margin: 0;
        &__item {
            &::before{
                content: '';
                position: absolute;
                top: 6px;
                left: 6px;
                width: 7px !important;
                height: 7px !important;
                border-radius: 50%;
                background: linear-gradient(164.08deg, #000040 2.97%, #091686 78.06%);
            }
        }
        &__title,   &__title.white{
            font-size: 16px;
            line-height: 20px;
            margin: 15px 0;
        }
        &__list {
            margin: 0;
        }  
        &__title, &__item {
            &::before {
                width: 7px !important;
                height: 7px !important;
                top: 4px !important;
                left: 4px !important;
            }
        }
        &__title.blue-and-white {
            &::before {
                width: 7px !important;
                height: 7px !important;
                top: 22px !important;
                left: 6px !important;
            }
        }
        &__item.blue-and-white {
            &::before {
                width: 4px !important;
                height: 4px !important;
                top: 5px !important;
                left: 6px !important;
            }
        }
    }
}