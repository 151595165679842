.container-title {
    text-align: left;
    font-style: normal;
    font-weight: bold;
    color: #0B1870;
    white-space: pre-wrap;

    &.sub {
        font-size: 34px;
        line-height: 38px;
    }

    &.main {
        font-size: 40px;
        line-height: 46px;
        font-weight: 900;
        font-family: 'Lato';
        text-align: center;
    }
    &.white {
        color: #0B1870;
    }
    &.blue {
        color: #FFFFFF;
    }
}

@media(max-width: 768px){
    .container-title {
        &.sub {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.01em;
            margin: 15px 0;
        }
        &.main {
            text-align: center;

            font-family: Lato;
            font-style: normal;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
        }
    }
}