.how-we-work {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #FFFFFF;

    &__bottom {
        width: 100%;
    }
    &__sub-title {
        font-family: 'OpenSans';
        font-size: 24px;
        line-height: 32px;
        color: #0B1870;
        font-weight: bold;
        margin: 45px 0 0 0;

        &.white {
            color: #FFFFFF;
        }
    }
    &__text {
        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 22px;
        color: #000021;
        margin: 13px 0 0 0;

        &.white {
            color: #FFFFFF;
        }

        ol {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            list-style: none;
            counter-reset: my-awesome-counter;
            padding: 0; 

            p {
                width: 20px;
                height: 26px;
                margin: 0;
                background: url('/img/how-we-work/right-blue-arrow.svg') center no-repeat;

                &.white {
                    background: url('/img/how-we-work/right-arrow.svg') center no-repeat;
                }
            }
          }
          li {
            display: flex;
            flex-direction: column;
            counter-increment: my-awesome-counter;
            line-height: 25px;
            width: 15%;
            text-align: center;
            
            
          }
          li::before {
            display: flex;
            content: counter(my-awesome-counter);
            border: 2px solid #0B1870;
            box-sizing: border-box;
            padding: 0 5px;
            margin: 0 auto 10px auto;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            align-items: center;
            justify-content: center;
            color: #0B1870;
        }
        &.white li::before {
            border: 2px solid #FFFFFF;
            color: #FFFFFF;
        }

    }
}

@media(max-width: 768px) {
    .how-we-work {
        &__sub-title {
            margin: 15px 0 0 0;
            font-size: 16px !important;
        }
        &__left {

            margin: 0;
            order: 2;
        }
        &__right, &__right p {
            margin: 22px 0 0 0;
            font-size: 16px !important;
            line-height: 20px;
            text-align: center;

            order: 1;
        }

        &__bottom {
            order: 3;
        }

        &__text {
            flex-direction: column;

            ol, ol.white {
                flex-direction: row;
                flex-wrap: wrap;
                padding: 0;
                margin: 0;
                justify-content: flex-start;

                p {
                    display: none;
                }
            }
            li {
                  flex-direction: row;
                  width: 100%;
                  text-align: center;
                  padding: 5px 0;
            }
            li::before {
                  margin: 0 10px 0 0;
            }
        }
    }
}