.services-srm {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &__content {
        display: flex;
        flex-direction: row;
    }

    &__title {
        width: 100%;
        text-align: center;
        color: #0B1870;
        font-family: 'Lato';
        font-weight: 900;
        font-size: 40px;
        line-height: 46px;
    }

    &__text {
        display: flex;
        color: #000021;
        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        width: 50%;

        &--left {
            padding-right: 40px;
        }

        &--right {
            padding-left: 40px;
        }
    }

    &__button {
        margin: 72px auto 0 auto;
        position: relative;
        display: block;
    }
}


@media(max-width: 768px) {
    .services-srm {
        &__container {
            justify-content: center;
            min-height: 0;
            width: 100%;
            flex-wrap: wrap;
        }
        &__content {
            flex-direction: column;
        }
        &__text {
            width: 100%;
            &--left {
                padding-right: 0px;
                line-height: 16px;
                letter-spacing: 0.01em;
            }
    
            &--right {
                margin: 15px 0 0 0;
                padding-left: 0px;
                line-height: 16px;
                letter-spacing: 0.01em;
            }
        }
        &__button {
            margin: 25px 0 0 0;
        }
        &__title {
            margin: 30px 0 0 0;
        }
    }
}