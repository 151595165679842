.footer {
    background: linear-gradient(90deg, 
    #000040 50.04%, 
    #091686 102.58%);

    &__content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    &__logo {
        flex-shrink: 0;
        width: 215px;
        height: 27px;
        background-image: url('/img/logo.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        margin: 0 0 auto 0;
    }

    &__list {
        font-family: 'OpenSans';
        margin: 0;
        padding-left: 0;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 27px;
        height: 81px;
        font-weight: 300;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        max-width: 700px;

        li {
            text-align: left;
        }
    }

    &__copyright {
        margin: 0;
        display: flex;
        font-family: 'OpenSans';
        display: flex;
        align-items: flex-end;
        color: #FFFFFF;
        line-height: 27px;
    }
    &__promo {
        display: flex;
        flex-direction: column;
        font-family: 'OpenSans';
        color: #FFFFFF;
        font-size: 12px;
    }
}
.footer.section::after {
        display: none;
}
@media (max-width: 768px) {
    .footer {
        &__content {
            flex-direction: column-reverse;
        }
        &__container {
            align-items: flex-start !important;
            padding: 5px 15px;
        }
        &__logo {
            display: none;
        }
        &__copyright {
            width: 100%;
            justify-content: center;
            margin: 20px 0 0 0;
        }
        &__list {
            height: 180px;
            width: 85vw;
            line-height: 34px;
            text-decoration: underline;

            a{
                padding: 0 0 0px 12px;
            }
        }
    }
}