    .components-and-business-functions-sap-s4hana {
        position: relative;
        width: 100%;
        padding: 60px 0;

        &__title {
            text-align: center;
            letter-spacing: 1px;

            color: #0B1870;

            font-family: 'Lato';
            font-size: 40px;
            font-weight: 900;
            font-style: normal;
            line-height: 46px;
            max-width: 700px;
            margin: 0 auto 60px auto;
        }
    }

    @media(max-width: 768px) {
        .components-and-business-functions-sap-s4hana {
            padding: 0;
            &__title {
                font-size: 18px;
                line-height: 22px;
                margin: 15px 0;

                &:last-child {
                    margin-bottom: 0px;
                }
            }
            &::before {

            content: '';
            position: absolute;
            top: -20px;
            left: 0;
            width: 100%;
            height: 6px;
            background: white;
            }
    }
}
