.form-contacts {
    position: absolute;
    position: fixed;
    top: 35vh;
    right: 0;
    overflow: hidden;
    z-index: 2;
    height: 60px;
    width: 60px;
    border-radius: 6px 0px 0px 6px;
    &.active {
        overflow: visible;
        border-radius: none;
        .form-contacts__wrap {
            padding: 30px;
            border: 2px solid #FB6247;
            border-right-width: 0;
            background: #FFFFFF;
            box-sizing: border-box;
            border-radius: 6px 0px 0px 6px;
            z-index: 3;
        }
    }

    &__checkbox {
        display: flex;

        margin: 15px 0;
    }
    
    &__box [type = "checkbox"] { 
        display: none;
    }
    
    &__box label {
        position: relative;

        display: block;

        margin: 2px 0;
        width: 26.96px;
        height: 26.54px;

        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.24949);
    }
    
    
    &__box label::after {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 27px;
        height: 27px;

        content: "";
        transform: translate(-50%, -50%);

        background: url("/img/check.svg") no-repeat center;
    
    }
    
    &__box input[type="checkbox"] + label::after {
        content: none;
    }
    
    &__box input[type="checkbox"]:checked + label::after {
        content: "";
    }
    
    &__label {
        padding-left: 17px;

        font-family: 'OpenSans';
        font-size: 14px;
        font-weight: '400';
        line-height: 16px;
    }
    
    &__checkbox-text {
        margin: 0;

        color: #3B3B43;
    }
    
    &__link {
        margin: 0;

        text-decoration: underline;

        color: #1D67A4;
    }
}

.form-contacts__wrap {
    transition: background 0.2s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition: background 0.2s ease-in-out;
    -moz-transition: background 0.2s ease-in-out;
    -ms-transition: background 0.2s ease-in-out;
    -o-transition: background 0.2s ease-in-out;
}
.form-contacts__title {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    color: #0B1870;
    margin: 0;
}
.form-contacts__text {
    margin: 15px 0;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #000021;
}
.form-contacts__phone {
    margin: 0;
    width: 100%;
    padding: 0 0 5px 37px;
    
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #0B1870;

    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        background: url('/img/contacts-phone-blue.svg') no-repeat center;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }
}

.form-contacts__email-label {
    display: block;
    margin: 12px 0;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #000021;
}


.form-contacts__input {
    border: none;
    width: 100%;
    background: #FFFFFF;
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.24949);
    border-radius: 100px;
    padding: 8px 15px;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #49496C;
    margin: 15px 0 0 0;
}

.form-contacts__message {
    margin: 0;
    padding-left: 37px;
    padding-top: 11px;
    padding-bottom: 12px;
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #0B1870;

    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        background: url('/img/contacts-message.svg') no-repeat center;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid rgba(73, 73, 108, 0.3);
    }
}

.form-contacts__button {
    width: 100%;
    position: relative;
    margin-top: 20px;
    font-family: 'OpenSans';
    font-size: 24px;
    line-height: 32px; 
    transition: background 0s ease-out;   
}

.form-contacts__button-exit {
    border: none;
    outline: none;
    padding: 0;

    width: 18px;
    height: 18px;

    background: transparent;
    background-image: url('/img/button-exit.svg');

    position: absolute;
    top: 30px;
    right: 30px;
    transition: transform 0.4s ease-out;
    &:hover {
        transform: rotate(90deg);
    } 
}

@media(max-width: 768px)
{
    .form-contacts {
        display: none;
    }
}
@media(max-height: 700px)
{
    .form-contacts__wrap {
        margin-top: -100px;
    }
}
@media(max-height: 500px)
{
    .form-contacts__wrap {
        margin-top: -150px;
    }
}