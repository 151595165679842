.intelligent-it-solutions {

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }
    &__advantages {
            display: flex;
            justify-content: center;
            flex-direction: row;
            
            width: 100%;
            padding: 0 15px;
            margin: 0;

            list-style: none;

            text-align: center;

            color: #0B1870;

            font-family: 'Lato';
            font-size: 34px;
            font-weight: bold;
            font-style: normal;
            line-height: 38px;

        }
        
        &__advantages-item {
            position: relative;

            padding-left: 37px;
            margin-left: 27px;
            &::before {
                position: absolute;
                top: 50%;
                left: 0;

                display: block;

                width: 10px;
                height: 10px;

                content: "";

                border-radius: 50%;
                background-color: #FB6247;
                transform: translateY(-50%);
            }

            &:first-child{
                padding: 0;
                margin: 0;
            }

            &:first-child::before {
                display: none;
            }
        }
        
        &__text {
            width: 100%;
            max-width: 800px;
            margin: 45px auto;
            padding: 0 15px;
            /* or 120% */

            text-align: center;

            font-family: 'OpenSans';
            font-size: 20px;
            font-weight: normal;
            font-style: normal;
            line-height: 24px;
        }
    .marked-list {
        margin: 0;
    }
}

@media (max-width: 768px) {
    .intelligent-it-solutions {
        &__text {
            margin: 25px 0;
            line-height: 20px;
        }
        &__advantages{
            margin: 0;
            line-height: 24px;
            &-item {
                font-size: 14px !important; 
                line-height: 18px;
                padding-left: 26px;
                margin-left: 18px;
            }
        }
    }
}