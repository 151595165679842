.key-effect {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &__title {
        width: 100%;
        text-align: center;
        color: #0B1870;
        font-family: 'Lato';
        font-weight: 900;
        font-size: 40px;
        line-height: 46px;
        margin: 0;
    }

    &__list {
        padding: 0;
    }

    &__item {
        padding-left: 12px;
        margin: 0 0 22px 0;
        font-family: 'OpenSans';
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #000021;
    }
}

@media(max-width: 768px) {
    .key-effect {
        &__text {
            margin: 25px 0 0 0; 
        }
        &__title {
            text-align: center;
            font-weight: 900;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: 0.01em;
        }
    }
}