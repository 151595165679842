.feedback {

    &__container {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        max-width: 1170px;
        justify-content: space-between;

        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }
    
    &__title {
        align-self: center;

        width: 100%;

        text-align: center;

        color: #FFFFFF;

        font-family: 'Lato';
        font-size: 40px;
        font-weight: 900;
    }
    
    &__contacts {
        margin-top: 31px;
        margin-bottom: 40px;
        // margin-right: auto;
        color: #FFFFFF;

        font-family: 'OpenSans';
    }

    &__button {
        margin: 0 auto;
    }
    
    &__contacts-title {
        margin: 0 0 0 45px;

        font-size: 24px;
        font-weight: 700;
    }
    
    &__list {
        margin: 0;
        padding-left: 0;

        list-style: none;

        font-size: 20px;
    }
    
    &__item-phone,  &__item-email, &__item-adress {
        position: relative;

        margin-top: 38px;
        padding-left: 60px;
        &::before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0px;

            width: 27px;
            height: 27px;

            content: '';

            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
    }
    
    &__item-phone::before {
        background-image: url("/img/phone-contact.svg");
    }

    &__item-email::before {
        background-image: url("/img/email-contact.svg");
    }
    
    &__item-adress::before {
        top: 10px;          
        background-image: url("/img/placeholder-contact.svg");
    }
    
    &__modal {
        display: flex;
        justify-content: center;
        flex-direction: column;

        padding: 33px 57px;

        max-width: 500px;

        border-radius: 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    }
    
    &__info {
        margin: 9px 0 0 0;

        color: #000021;

        font-family: 'OpenSans';
        font-size: 18px;
        font-weight: 500;
    }
    
    &__name {
        align-self: center;

        width: 100%;
        height: 35.38px;
        margin-top: 18px;
        padding-left: 20px;

        color: #49496C;
        border: 0;
        border-radius: 100px;
        outline:none;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.24949);

        font-family: 'OpenSans';
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
    }
    
    &__email {
        width: 100%;
        height: 35.38px;
        margin-top: 33px;
        padding-left: 20px;

        color: #49496C;
        border: 0;
        border-radius: 100px;
        outline:none;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.24949);

        font-family: 'OpenSans';
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
    }
    
    &__message {
        width: 100%;
        height: 164.14px;
        margin-top: 33px;
        padding: 10px 20px 10px 20px;

        resize: none; 

        color: #49496C;
        border: 0;
        border-radius: 11px; outline:none;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.24949);

        font-family: 'OpenSans';
        font-size: 17px;
        font-weight: 400;
        line-height: 24px;
        }
    
    &__checkbox {
        display: flex;

        margin: 15px 0;
    }
    
    &__box [type = "checkbox"] { 
        display: none;
    }
    
    &__box label {
        position: relative;

        margin: 2px 0;
        display: block;

        width: 26.96px;
        height: 26.54px;

        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.24949);
    }
    
    
    &__box label::after {
        position: absolute;
        top: 50%;
        left: 50%;

        width: 27px;
        height: 27px;

        content: "";
        transform: translate(-50%, -50%);

        background: url("/img/check.svg") no-repeat center;
    
    }
    
    &__box input[type="checkbox"] + label::after {
        content: none;
    }
    
    &__box input[type="checkbox"]:checked + label::after {
        content: "";
    }
    
    &__label {
        padding-left: 17px;

        font-family: 'OpenSans';
        font-size: 14px;
        font-weight: '400';
        line-height: 16px;
    }
    
    &__checkbox-text {
        margin: 0;

        color: #3B3B43;
    }
    
    &__link {
        margin: 0;

        text-decoration: underline;

        color: #1D67A4;
    }
}

.feedback--white {
    background: #FFFFFF;

    .feedback__title, .feedback__modal-title {
        color: #0B1870;
    }

    .feedback__info {
        color: #000021;
    }
    
    .feedback__contacts-title {
        color: #0B1870;
    }

    .feedback__contacts {
        color: #000021;
    }

    .feedback__checkbox-text {
        color: #3B3B43;
    }

    .feedback__link {
        color: #377BBC;
    }

    .feedback__item-phone {
        &::before {
            background-image: url("/img/phone-contact-blue.svg");
        }
    }
    
    .feedback__item-email {
        &::before {
            background-image: url("/img/email-contact-blue.svg");
        }
    }
    
    .feedback__item-adress {
        &::before {
            background-image: url("/img/placeholder-contact-blue.svg");
            left: 0;
        }
    }

}

.feedback--blue {
    width:100%;
    background: linear-gradient(90deg, 
    #000040 50.04%, 
    #091686 102.58%);
}

@media(max-width: 768px) {
    .feedback {
    
        &__container {
            flex-flow: column-reverse;
        }
        &__modal {
            color: white;
            background-color: rgba(0, 0, 0, 0);
            box-shadow: none;
            width: 100%;
            max-width: 100%;

            margin: 0;
            padding: 0px;
        }
        &__modal-title {
            color: white;
            font-size: 18px;
            margin: 0;

            order: 5;
        }
        &__link, &__checkbox-text {
            color: white;
            font-size: 12px;
        }
        &__info {
            color: white;
            font-size: 12px;
            text-align: center;
        }
        &__name, &__email, &__message {
            font-size: 12px;
            margin: 20px 0 0 0;

        }
        &__contacts {
            margin: 0;
            width: 100%;
            &-title {
                display: none;
            }
        }
        &__item-adress::before, &__item-email::before,  &__item-phone::before {
            width: 20px;
            height: 20px;
        }
        &__item-email, &__item-adress{
            margin-top: 22px;
            padding-left: 36px;
        }
        &__item-phone {
            margin: 0;
            padding-left: 36px;
        }
        &__content {
            margin: 25px 0;
            width: 100%;
        }
        &__list {
            width: 100%;
            padding: 0 15px;
        }
        &__title {
            margin: 30px 0 0 0;
        }
        &__button, &__button--disabled{
            margin: 30px auto;
        }
    }
    
}