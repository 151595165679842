@keyframes jump {
    0%   {transform: translate3d(0,-100%,0);}
    100% {transform: translate3d(0,-20%,0)}
  }
  .jump {
    transform-origin: 50% 50%;
    animation: jump 1s linear alternate infinite;
  }

.arrow {
    width: 36px;
    height: 36px;
    background: url('/img/statement/arrow-to.svg') center no-repeat;
    background-size: contain;
    animation: jump 1s linear alternate infinite;
    z-index: 5;
    -webkit-animation: jump 2s linear alternate infinite;
}

