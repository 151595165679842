.our-clients {
    display: block !important;
    
    background: linear-gradient(90deg, #000040 50.04%, #091686 102.58%);
    
    .slick-list {
        height: 70px;
    }
}
.our-clients__container {
    padding: 0 15px;
    margin: 0 auto;
}

.slider {
    padding: 0 45px;
}
.slider__item-img {
    display: block;
    height: 70px;
    margin: 0 auto; 
}

.slick-prev:before, 
.slick-next:before {
    content: '';
    font-size: 0;
    display: block;
    height: 40px;
    width: 25.24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.slick-prev:before {
    background-image: url('/img/slider/arrow-prev.svg')
}

.slick-next:before {
    background-image: url('/img/slider/arrow-next.svg')
}

.slick-prev, 
.slick-next {
    width: 25.24px;
    height: 40px;
    padding: 0;
    cursor: pointer;
    color: transparent;
}

.slick-prev {
    left: -40px;
}

.slick-next {
    right: -40px;
}

@media(max-width: 768px) {
    .our-clients {
        .slick-list {
            height: 46px;
        }      
    }
    .slider__item{
        margin: 0 14px;
    }
    .slider__item-img {
        height: 42px;
    } 
    .slider {
        margin-top: 26px;
        padding: 0 40px;
    }
    .slick-prev, .slick-prev::before, 
    .slick-next, .slick-next::before {
        height: 20px;
        width: 11.62px;
    }
    
}