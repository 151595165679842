.services {
    background-image: url("/img/main-page/services-background.jpg");
    background-size: contain;

    &__title {
        text-align: left;
        color: #0B1870;
        margin: 0;

        &.white {
            color: white;
        }
    }
    
    &__list {
        margin: 9px 0 0 0;
        display: flex;
        max-height: 150px;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        font-family: 'OpenSans';
        font-weight: 400;
        color: #000021;
        font-size: 18px;
        line-height: 35px;
        width: 40%;
        max-width: 1170px;

        &__column {
            list-style-type: none;
            padding: 0;
        }
    }
    
    &__button {
        position: relative;
        margin: 0;
        margin-top: 103px;
        width: 310px;
    }
}

@media (max-width: 768px) {
    .services {
        background-image: none !important;
        &__container {
            justify-content: space-between;
        }

        &__list {
            height: 120px;
            width: 100%;
            padding: 0 15px;
            line-height: 26px;
        }
    }

}