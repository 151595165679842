.message-sent {
        display: none;
        width: 100vw;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background: rgba(11, 24, 112, 0.8);

        position: fixed;
        top: 0;
        left: 0;
        
        z-index: 5;

        &__modal {
            display: flex;
            justify-content: center;
            flex-direction: column;
    
            padding: 48px;
            margin: 15px;
    
            max-width: 500px;
    
            border-radius: 8px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
        }
        &__button {
            margin: 0 auto;
        }
        &__info {
            margin: 20px 0 38px 0;
    
            color: #000021;
    
            font-family: 'OpenSans';
            font-size: 20px;
            line-height: 24px;
            font-weight: 500;
            text-align: center;
        }
        &__button-exit {
            border: none;
            outline: none;
            padding: 0;
            width: 18px;
            height: 18px;
            background: transparent;
            background-image: url("/img/button-exit.svg");
            transition: transform 0.4s ease-out;

            margin-left: auto;
            margin-top: -25px;
            margin-right: -25px;
        }
        
    }
    .opened {
        display: flex;
    }
    

@media(max-width: 768px) {
    .message-sent {
        &__button, &__button--disabled{
            margin: 0 auto;
        }

        &__modal {
            padding: 30px;
        }
        &__info {
            margin: 10px 0 20px 0;
            line-height: 18px;
        }
        &__button-exit {
            margin-top: -15px;
            margin-right: -15px;
        }
    }
}