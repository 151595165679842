.what-partners-get {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url('/img/nonSAP/background-what-partners-get.jpg');
    background-position: right;
    background-size: contain !important;
  //  background-position-y: 160px; разкомментировать при возврате прямоугольников
    background-repeat: no-repeat;

    &__container {
        max-width: 1030px;
        width: 100%;
     //   margin: -150px 0 0 0; разкомментировать при возврате прямоугольников
        z-index: 1;

    }

    &__title {
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 38px;
        margin: 0;

        color: #FFFFFF;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        margin: 36px 0 0 0;
        padding: 0;
    }

    &__item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        width: 24%;
        max-width: 230px;
        min-height: 254px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
        border-radius: 8px;
        padding: 0 18px;
        text-align: center;
        color: #000021;
        font-family: 'OpenSans';
        font-size: 18px;
    }

    &__thesis {
        line-height: 22px;
        margin: 0;
        font-weight: bold;
    }

    &__description {
        margin: 18px 0 0 0;
        line-height: 24px;
    }

    &__text {
        max-width: 600px;
        width: 100%;
        font-family: 'OpenSans';
        font-size: 18px;
        line-height: 22px;
        color: #000021;
        p {
            margin: 0;
            margin-bottom: 22px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

@media(max-width: 768px) {
    .what-partners-get {
        background: none;
        &__list {
            margin: 20px 0 0 0; 
            flex-wrap: wrap;
        }
        &__item {
            width: 48%;
            padding: 15px;
            margin: 0 0 15px 0;
        }
        &__text {
            margin: 0;
        }
        &__container {
            padding: 0;
        }
    }
}