.sap-solutions {
    background-image: linear-gradient(90deg, 
    #FFFFFF 1.68%, 
    rgba(255, 255, 255, 0) 71.87%),
    url("/img/main-page/sap-solutions-background.svg");

    &__list {
        display: flex;
        max-height: 150px;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        font-family: 'OpenSans';
        font-weight: 400;
        color: #000021;
        font-size: 18px;
        line-height: 35px;
        width: 100%;
        max-width: 535px;

        &__column {
            list-style-type: none;
            padding: 0;
        }
    }
        
    &__title{
        text-align: left;
        color: #0B1870;

        &.white {
            color: white;
        }
    }
}

@media (max-width: 768px) {
    .sap-solutions {
        background-image: linear-gradient(90deg, #FFFFFF 1.68%, rgba(255, 255, 255, 0) 71.87%);

        &__list {
            &__column {
                align-items: space-beetwen;
                width: 100%;
                height: 52px;
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                flex-wrap: wrap;

                margin: 0;
                padding: 0 15px;
                li {
                    text-align: left;
                    width: 50%;
                    line-height: 26px;
                }
            }
        }

        &__title{
            text-align: left;
            width: 100%;
            padding: 0 15px;
        }
    }
    .card-list__item-img-wrap {
        img {
            height: 50px !important;
            margin-right: 15px;
        }
        height: fit-content !important;
    }
}