.two-column-text {

    &__information {
        width: 100%;
        color: #000021;

        margin: 0 auto;
    }

    &__information-title {
        margin: 0;
        color: #0B1870;
    }
    &__blocks_container {
        margin: 0;
        display: flex;
        justify-content: space-between;
    }
    &__block {
        white-space: pre-line;
        width: 47%;
    }
    
}
@media (max-width: 768px) {
    .two-column-text {
        &__blocks_container {
            display: inline;
        }
        &__block {
            width: 100%;
            font-size: 14px;
            line-height: 16px;

            margin: 0 0 15px 0;

            &:last-child{
                margin: 0;
            }
        }
        &__information-title {
            margin: 0 0 18px 0;
        }
    }
}