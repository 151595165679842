.header {
    width: 100%;
    max-width: 1280px;
    color: #fff;
    z-index: 2; //должен быть болmше 1 что бы не заткмнялся
}

.header__row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    padding-left: 64px;
    padding-right: 15px;
    margin-left: 0;
    margin-right: auto;
    max-width: 1110px;
    width: 100%;
}

.header__logo {
    width: 215px;
    height: 27px;
    background-image: url('/img/logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex-shrink: 0;
}

.header__hamburger {
    color: white;
    height: 16px;
    width: 27px;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('/img/hamburger.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
    outline: none;
    border: none;
    position: relative;

    &.open {
        background-image: url('/img/hamburger-open.svg');
    }

    &.close {
        background-image: url('/img/hamburger.svg');
    }
}

.header__text {
    margin: 0;
    margin-left: 29px;
    margin-right: auto;
    width: 170px;
    font-size: 16px;
    line-height: 18px;
    flex-shrink: 0;
} 

.header__service-menu {
    font-size: 16px;
    line-height: 32px;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    flex-shrink: 3;
}

.header__main-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 700; 
    line-height: 32px;
    min-height: 50px;
    width: 100%;
    max-width: 900px;
    padding: 0 15px;
    margin: 0 auto;
}

.nav__item {
    padding: 0 16px;
}
.nav__item {
    &:hover, 
    &.active {
        position: relative;
        background-image: url('/img/dot.svg'), url('/img/dot.svg');
        background-repeat: no-repeat, no-repeat;
        background-size: 6px 6px;
        background-position: left center, right center;
    }
}

.nav__item--sub-menu-sap-solutions {
    position: relative;
    padding-right: 39px;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 17px;
        width: 14px;
        height: 100%;
        background: url('/img/nav-arrow.svg') no-repeat center;
        background-size: 14px 8.14px; 
        transform: translateY(-50%) rotate(0);
        transition: transform 0.7s ease-out;
    }
    ul {
        position: absolute;
        transform: scale(0);
        list-style-type: none;
        height: 90px;
        width: 328px;
        margin: 0;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
        border-radius: 0px 8px 8px 8px;
        padding: 9px 17px;
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
        align-content: stretch; 
    }

    li {
        font-family: 'OpenSans';//'Lato';
        font-size: 18px;
        line-height: 32px;
        color: #000040;
        text-align: left;
        width: 52%;
        &:hover {
            text-decoration-line: underline;
            color: #FB6247;
        }
    }
    &:hover {
        &::after {
            transform: translateY(-50%) rotate(180deg);
        }
        ul {
            transform: scale(1); 
        }
    }
}

.nav__item--sub-menu-services {
    position: relative;
    padding-right: 39px;
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 17px;
        width: 14px;
        height: 100%;
        background: url('/img/nav-arrow.svg') no-repeat center;
        background-size: 14px 8.14px; 
        transform: translateY(-50%) rotate(0);
        transition: transform 0.7s ease-out;
    }
    ul {
        position: absolute;
        transform: scale(0);
        list-style-type: none;
        width: 400px;
        height: 148px;
        margin: 0;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
        border-radius: 0px 8px 8px 8px;
        padding: 9px 17px;
        padding-bottom: 5px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: stretch;
        align-content: stretch; 

    }

    li {
        padding-right: 20px;
        font-family: 'OpenSans';//'Lato';
        font-size: 18px;
        line-height: 32px;
        color: #000040;
        text-align: left;
        &:hover {
            text-decoration-line: underline;
            color: #FB6247;
        }
    }
    &:hover {
        &::after {
            transform: translateY(-50%) rotate(180deg);
        }
        ul {
            transform: scale(1); 
        }
    }
}



@media (max-width: 768px) {
    .header{
        &__text {
            display: none;
        }
        &__row {
            padding: 15px;
        }
        &__logo {
            height: 12px;
            background-position: left;
        }
        &__hamburger {
            display: flex;
            $display: none;
            :active {
                display: flex;
            }
        }
        &__main-menu{
            font-weight: bold;
            margin: 12px 0;
        }
        &__service-menu{
            text-decoration: underline;
            width: 100%;
        }
    }
        .mobile__nav {
            display: flex;
            padding: 80px 0;
            background: #FFFFFF;
            color: black;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 40px;
            position: absolute;
            width: 100%;

            .nav {
                flex-direction: column;
                text-align: center;
    
                font-family: Lato;
                font-size: 12px;
                line-height: 32px;
            }

            .nav__item {
                padding: 0 16px;
                margin: 0 auto;
            }

            .nav__item--sub-menu-services, .nav__item--sub-menu-sap-solutions {
                &::after {
                    background: url("/img/nav-arrow-blue.svg") no-repeat center;
                    background-size: 10px 6px;
                    right: 3px;
                }
                ul {
                    z-index: 5;
                    
                }
            }

            .nav__item--sub-menu-sap-solutions ul{
                right: -106px;
                border-radius: 0px;
            }

            .nav__item--sub-menu-services ul {
                right: -122px;
                border-radius: 0px;
            }

            .feedback__list {
                max-width: 150px;
                padding: 0;
                margin: 0 auto;
                
                .feedback__item-phone {
                    &::before {
                        background-image: url("/img/phone-contact-dark-blue.svg");
                    }
                }
                
                .feedback__item-email {
                    &::before {
                        background-image: url("/img/email-contact-dark-blue.svg");
                    }
                }
            }
        }
}