@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
        url('/fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
        url('/fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
        url('/fonts/OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('/fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
        url('/fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
        url('/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
        url('/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Bold.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Bold.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Medium.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-SemiboldItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-HeavyItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Thin.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Semibold.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-MediumItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Regular.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Regular.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-BoldItalic.woff') format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Light.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('/fonts/Lato/Lato-HairlineItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-HairlineItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Black.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('/fonts/Lato/Lato-Hairline.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Hairline.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Heavy.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Italic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-Italic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-Italic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato/Lato-ThinItalic.woff2') format('woff2'),
        url('/fonts/Lato/Lato-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}



