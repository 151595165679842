.key-features {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(4.89deg, #32B7FF 4.25%, #DDEEFD 55.49%);
    }

    &__container {
        padding: 63px 135px 70px 135px;
        max-width: 1280px;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        justify-content: space-between;
    }

    &__title {
        width: 100%;
        text-align: start;
        color: #0B1870;
        font-family: 'Lato';
        font-weight: bold;
        font-size: 34px;
        line-height: 38px;
        margin: 0;
    }

    &__list {
        margin: 48px 0 0 0;
        list-style: none;
        max-width: 476px;
        font-family: 'OpenSans';
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #000021;
        padding: 0;
    }

    &__icon-list {
        display: flex;
        list-style: none;
        flex-direction: column;
        padding: 0 0 0 50px;

        &__item {
            max-width: 346px;
            min-height: 60px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
            border-radius: 8px;
            padding: 10px;
            margin: 0 0 10px 0;
            display: flex;
            align-items: center;

            &::before {
                padding: 36px;
                content: '';
                margin: 0 35px 0 -100px;
            }

            &--autonom::before {
                    background: url('/img/key-features-autonom.svg') center no-repeat;
                    background-size: contain;
                }
            &--local::before {
                background: url('/img/key-features-local.svg') center no-repeat;
                background-size: contain;
            }
        }
    }

    &__right {
        max-width: 440px;
        margin: 48px 0 0 0;
        flex-wrap: wrap;
        justify-content: flex-end;
        display: flex;
    }

    &__sub-title {
        margin:  0 0 18px 0;
        font-family: 'Lato';
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #0B1870;
        max-width: 349px;
    }

    &__text {
        margin: 53px 0 0 0;
        max-width: 920px;
        font-family: 'OpenSans';
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: #000021;
    }
}

@media(max-width: 768px) {
    .key-features {
        &__title {
            font-weight: 900;
            font-size: 18px !important;
            line-height: 22px;
            text-align: center;
        }
        &__container {
            justify-content: center;
            min-height: 0;
            padding: 25px 15px;
            width: 100%;
            flex-wrap: wrap;
        }
        &__sub-title {
            width: 100%;
            font-weight: bold;
            font-size: 16px !important;
            line-height: 20px;
            max-width: 100%;
        }
        &__icon-list {
            padding: 0 0 0 57px;
            
            &__item {
                max-height: 220px;
                padding: 0px;
                padding: 10px;
                &::before {
                    padding: 25px;
                    margin-left: -80px;
                }
            }
        }
        &__right {
            justify-content: center;
            margin: 15px 0 0 0; 
        }  
        &__list {
            margin: 18px 0 0 0;
        }
        &__text {
            margin-top: 15px;
        }
    }
}